<template>
  <div class="select-none">
    <NuxtLink
      class="btn btn-white hover:btn-green mb-2.5 lg:mb-5"
      :to="loginUrl"
    >
      {{ $t('Login') }}
    </NuxtLink>
    <NuxtLink
      v-if="locale === 'nl'"
      class="btn btn-white hover:btn-green mb-5 lg:mb-11"
      :to="registerUrl"
    >
      {{ $t('Sign up') }}
    </NuxtLink>

    <div class="flex justify-center gap-4 lg:justify-start lg:gap-2.5">
      <SocialLink
        social="linked-in"
        :to="settings?.['linked-in']"
        aria-label="linked in"
      />
      <SocialLink
        social="twitter"
        :to="settings?.twitter"
        aria-label="Twitter"
      />
      <SocialLink
        social="facebook"
        :to="settings?.facebook"
        aria-label="Facebook"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig();
const loginUrl = config.public.loginUrl;
const registerUrl = config.public.registerUrl;

const {locale} = useI18n();

const settings = {
  'linked-in': 'https://nl.linkedin.com/company/groentenfruit-huis',
  twitter: 'https://twitter.com/gfhuis',
  facebook: 'https://nl-nl.facebook.com/groentenfruithuis/',
};
</script>
