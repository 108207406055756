<template>
  <ul class="flex select-none flex-col gap-8 lg:flex-row lg:gap-8">
    <li
      v-for="item in navItems"
      :key="item.url"
    >
      <NuxtLink
        v-if="!item.children"
        class="link-header break-words"
        :class="{'active': isActive(item), 'cursor-default': route.fullPath === item.url}"
        :to="item.url"
      >
        {{ item.text }}
      </NuxtLink>

      <!-- Accordion -->
      <div
        v-else
        class="group relative"
      >
        <!-- Mobile toggle -->
        <a
          class="link-header cursor-pointer space-x-1 lg:hidden"
          :class="{'text-green': isActive(item)}"
          @click.stop="toggle(item)"
        >
          <span>{{ item.text }}</span>
          <IconsActionsChevron
            class="inline h-4 transition-transform duration-300 ease-in-out"
            :class="{'-rotate-180': open[item.url]}"
          />
        </a>

        <div
          class="list overflow-hidden lg:hidden"
          :class="open[item.url] ? 'list-opened' : 'list-collapsed'"
        >
          <ul class="space-y-6 pl-4 pt-4 lg:p-6">
            <li v-if="item.text && item.url">
              <NuxtLink
                class="link-header"
                :class="{'active': isActive(item)}"
                :to="item.url"
              >
                {{ item.text }}
              </NuxtLink>
            </li>
            <li
              v-for="nestedLink in item.children"
              :key="nestedLink.url"
            >
              <NuxtLink
                class="link-header"
                :class="{'active': isActive(nestedLink)}"
                :to="nestedLink.url"
              >
                {{ nestedLink.text }}
                <IconsActionsChevron
                  v-if="nestedLink.children"
                  class="inline h-4 transition-transform duration-300 ease-in-out"
                  :class="{'-rotate-180': open[nestedLink.url]}"
                />
              </NuxtLink>
              <ul
                v-if="nestedLink.children"
                class="space-y-6 pl-4 pt-4 lg:p-6"
              >
                <li
                  v-for="nestedNestedLink in nestedLink.children"
                  :key="nestedNestedLink.url"
                >
                  <NuxtLink
                    class="link-header"
                    :class="{'active': isActive(nestedNestedLink)}"
                    :to="nestedNestedLink.url"
                  >
                    {{ nestedNestedLink.text }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <!-- Desktop hover -->
        <NuxtLink
          class="link-header group-hover:text-green space-x-1 max-lg:hidden"
          :class="isActive(item) && 'text-green'"
          :to="item.url"
        >
          <span>{{ item.text }}</span>
          <IconsActionsChevron class="inline h-4 transition-transform duration-300 ease-in-out group-hover:-rotate-180" />
        </NuxtLink>

        <div class="list list-collapsed group-hover:list-opened absolute -left-8 overflow-hidden px-2.5 group-hover:pb-4 group-hover:pt-6 max-lg:hidden">
          <ul class="space-y-6 rounded-b-xl bg-white pl-4 transition-all group-hover:shadow-lg lg:px-6 lg:pb-6 lg:pt-4">
            <li
              v-for="nestedLink in item.children"
              :key="nestedLink.url"
              class="group/child relative w-max max-w-xs"
            >
              <NuxtLink
                class="link-header break-words"
                :class="{'active': isActive(nestedLink)}"
                :to="nestedLink.url"
              >
                {{ nestedLink.text }}
                <IconsActionsChevron
                  v-if="nestedLink.children"
                  class="inline h-4 transition-transform duration-300 ease-in-out group-hover/child:-rotate-90"
                />
              </NuxtLink>
              <div
                v-if="nestedLink.children"
                class="list list-collapsed group-hover/child:list-opened absolute -right-6 -top-4 z-[100] translate-x-full overflow-hidden max-lg:hidden"
              >
                <ul
                  class="space-y-6 rounded-r-xl bg-white pl-4 transition-all group-hover/child:shadow-lg lg:px-6 lg:pb-6 lg:pt-4"
                >
                  <li
                    v-for="child in nestedLink.children"
                    :key="child.url"
                    class="w-max max-w-xs"
                  >
                    <NuxtLink
                      class="link-header break-words"
                      :class="{'active': isActive(child)}"
                      :to="child.url"
                    >
                      {{ child.text }}
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import type {MenuItem} from '~/graphql/graphql';

defineProps<{navItems: MenuItem[]}>();

const open = ref<{[key: string]: boolean}>({});

const toggle = (item: MenuItem) => {
  open.value[item.url] = !open.value[item.url];
};

const route = useRoute();
const isActive = (item: MenuItem): boolean => {
  const isHome = item.url === '/' && route.path === '/';
  const isRoute = item.url !== '/' && !!item.url && route.fullPath.startsWith(item.url);
  const hasChildRoute = item.children?.some(child => child && isActive(child)) ?? false;

  return isHome || isRoute || hasChildRoute;
};
</script>

<style scoped>
a, ul, li {
  @apply text-white lg:text-blue hover:text-green;
}
</style>
