<template>
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.15625 18.0898V6.41016H0.523438V18.0898H4.15625ZM2.32031 4.84766C3.49219 4.84766 4.42969 3.87109 4.42969 2.69922C4.42969 1.56641 3.49219 0.589844 2.32031 0.589844C1.1875 0.589844 0.25 1.56641 0.25 2.69922C0.25 3.87109 1.1875 4.84766 2.32031 4.84766ZM17.75 18.0898V11.6836C17.75 8.55859 17.0469 6.13672 13.375 6.13672C11.6172 6.13672 10.4453 7.11328 9.9375 8.01172H9.89844V6.41016H6.42188V18.0898H10.0547V12.3086C10.0547 10.7852 10.3281 9.33984 12.2422 9.33984C14.0781 9.33984 14.1172 11.0586 14.1172 12.4258V18.0898H17.75Z" />
  </svg>
</template>
