<template>
  <footer class="bg-blue">
    <ul class="lg:container max-lg:divide-y max-lg:divide-black/10 lg:flex">
      <li
        v-for="menu in navItems"
        :key="menu.text"
        class="container py-5 lg:flex-1 lg:py-20"
      >
        <div class="heading-footer">
          {{ menu.text }}
        </div>
        <nav>
          <ul class="space-y-2.5">
            <li
              v-for="item in menu.children"
              :key="item.text"
            >
              <NuxtLink
                :to="item.url"
                class="link-footer"
              >
                {{ item.text }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </li>

      <li class="container border-none py-5 lg:flex-1 lg:py-20">
        <LayoutAuthAndSocialLinks />
      </li>
    </ul>
  </footer>
</template>

<script lang="ts" setup>
const {menus} = useMenu();
const navItems = computed(() => menus.value.footer);
</script>
