<template>
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.9297 4.4375C18.7109 3.85156 19.4141 3.14844 19.9609 2.32812C19.2578 2.64062 18.4375 2.875 17.6172 2.95312C18.4766 2.44531 19.1016 1.66406 19.4141 0.6875C18.6328 1.15625 17.7344 1.50781 16.8359 1.70312C16.0547 0.882812 15 0.414062 13.8281 0.414062C11.5625 0.414062 9.72656 2.25 9.72656 4.51562C9.72656 4.82812 9.76562 5.14062 9.84375 5.45312C6.44531 5.25781 3.39844 3.61719 1.36719 1.15625C1.01562 1.74219 0.820312 2.44531 0.820312 3.22656C0.820312 4.63281 1.52344 5.88281 2.65625 6.625C1.99219 6.58594 1.32812 6.42969 0.78125 6.11719V6.15625C0.78125 8.14844 2.1875 9.78906 4.0625 10.1797C3.75 10.2578 3.35938 10.3359 3.00781 10.3359C2.73438 10.3359 2.5 10.2969 2.22656 10.2578C2.73438 11.8984 4.25781 13.0703 6.05469 13.1094C4.64844 14.2031 2.89062 14.8672 0.976562 14.8672C0.625 14.8672 0.3125 14.8281 0 14.7891C1.79688 15.9609 3.94531 16.625 6.28906 16.625C13.8281 16.625 17.9297 10.4141 17.9297 4.98438C17.9297 4.78906 17.9297 4.63281 17.9297 4.4375Z" />
  </svg>
</template>
