<template>
  <Transition
    name="from-top"
    enter-from-class="-translate-y-[110vh]"
    enter-active-class="transition-transform ease-in-out duration-300"
    enter-to-class="translate-y-0"
    leave-from-class="transform translate-y-0"
    leave-active-class="transition-transform ease-in-out duration-300"
    leave-to-class="-translate-y-[110vh]"
  >
    <slot />
  </Transition>
</template>
