<template>
  <svg
    width="21"
    height="18"
    viewBox="0 0 21 18"
    fill="#4E5F7E"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.4375 2.8125C20.7188 2.8125 21 2.57812 21 2.25V0.75C21 0.46875 20.7188 0.1875 20.4375 0.1875H0.5625C0.234375 0.1875 0 0.46875 0 0.75V2.25C0 2.57812 0.234375 2.8125 0.5625 2.8125H20.4375ZM20.4375 10.3125C20.7188 10.3125 21 10.0781 21 9.75V8.25C21 7.96875 20.7188 7.6875 20.4375 7.6875H0.5625C0.234375 7.6875 0 7.96875 0 8.25V9.75C0 10.0781 0.234375 10.3125 0.5625 10.3125H20.4375ZM20.4375 17.8125C20.7188 17.8125 21 17.5781 21 17.25V15.75C21 15.4688 20.7188 15.1875 20.4375 15.1875H0.5625C0.234375 15.1875 0 15.4688 0 15.75V17.25C0 17.5781 0.234375 17.8125 0.5625 17.8125H20.4375Z" />
  </svg>
</template>
